
import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Button,
  Grid,
  styled,
  TablePagination,
  tableCellClasses,
  CircularProgress,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { format, parseISO } from "date-fns";
import { useAuthCheck } from "../utils/Auth";
import DownloadButton from "../utils/DownloadButton";
import "../assets/css/forms.css";
import admlogo from "../assets/images/adm.png";
import { commonService } from "../api";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  formatFurnace2Data,
  formatFurnace3Data,
  formatCracker2Data,
  formatCracker3Data
} from "../utils/ReportFormatters";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const getCurrentDate = () => {
  const today = new Date();
  return format(today, "yyyy-MM-dd");
};

export default function ADMReports() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [reportData, setReportData] = useState({
    lineNo: "",
    shiftId: "",
    startDate: getCurrentDate(),
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [selectedLine, setSelectedLine] = useState("");
  useAuthCheck();

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReportData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "lineNo") {
      setSelectedLine(value);
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formattedStartDate = format(parseISO(reportData.startDate), "dd-MMM-yyyy");

      const requestData = {
        shiftId: parseInt(reportData.shiftId),
        startDate: formattedStartDate,
      };

      let result;
      switch (reportData.lineNo) {
        case "furnace2report":
          result = await commonService.postFurnace2Report(requestData);
          break;
        case "furnace3report":
          result = await commonService.postFurnace3Report(requestData);
          break;
        case "cracker2report":
          result = await commonService.postCracker2Report(requestData);
          break;
        case "cracker3report":
          result = await commonService.postCracker3Report(requestData);
          break;
        default:
          throw new Error("Invalid device selected");
      }

      handleSnackbarOpen("Report data fetched successfully!", "success");

      if (result.data && result.data.length > 0) {
        const headers = Object.keys(result.data[0]);
        setTableHeaders(headers);
        setData(result.data);
      } else {
        setTableHeaders([]);
        setData([]);
      }
    } catch (error) {
      handleSnackbarOpen("Error fetching report data. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const formattedStartDate = format(parseISO(reportData.startDate), "dd-MMM-yyyy");
      const requestData = {
        shiftId: parseInt(reportData.shiftId),
        startDate: formattedStartDate,
      };

      let result;
      let formatFunction;
      switch (reportData.lineNo) {
        case "furnace2report":
          result = await commonService.postFurnace2Report(requestData);
          formatFunction = formatFurnace2Data;
          break;
        case "furnace3report":
          result = await commonService.postFurnace3Report(requestData);
          formatFunction = formatFurnace3Data;
          break;
        case "cracker2report":
          result = await commonService.postCracker2Report(requestData);
          formatFunction = formatCracker2Data;
          break;
        case "cracker3report":
          result = await commonService.postCracker3Report(requestData);
          formatFunction = formatCracker3Data;
          break;
        default:
          throw new Error("Invalid device selected");
      }

      return { data: result.data, formatFunction };
    } catch (error) {
      handleSnackbarOpen("Error fetching report data. Please try again.", "error");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "0px 20px", width: "100%" }}>
      <Grid container  alignItems="center" style={{ marginTop: "20px" }}>
        <Grid item>
          <img src={admlogo} alt="Left Company Logo" style={{ height: "100px" }} />
        </Grid>
        <Grid item sx={{margin:'auto'}}>
          <h1>
            Production Cum Process Report
            {selectedLine && (
              <span style={{ fontWeight: "bold", fontSize: "2rem", marginLeft: "10px" ,}}>
                : {selectedLine.replace("report", "").replace(/[0-9]/g, "").toUpperCase()} {selectedLine.match(/\d+/)[0]}
              </span>
            )}
          </h1>
        </Grid>
      </Grid>

      <Grid container style={{ width: "100%", alignItems: "center", margin: "10px 0px 10px 0px" }}>
        <Grid item xs={6} sm={3} md={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Device</InputLabel>
            <Select name="lineNo" value={reportData.lineNo} onChange={handleInputChange}>
              <MenuItem value="furnace2report">Furnace 2</MenuItem>
              <MenuItem value="furnace3report">Furnace 3</MenuItem>
              <MenuItem value="cracker2report">Cracker 2</MenuItem>
              <MenuItem value="cracker3report">Cracker 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <FormControl sx={{ minWidth: 250 ,marginLeft:'20px'}}>
            <TextField
              label="Start Date"
              name="startDate"
              type="date"
              value={reportData.startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250,marginLeft:'20px' }}>
            <InputLabel>Select Shift</InputLabel>
            <Select name="shiftId" value={reportData.shiftId} onChange={handleInputChange}>
              <MenuItem value="1">Day</MenuItem>
              <MenuItem value="2">Night</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleAddSubmit} sx={{marginLeft:'20px'}}>
            OK
          </Button>
        </Grid>
      </Grid>

      <DownloadButton 
        apiCall={handleDownload}
        fileName={`${reportData.lineNo}_${reportData.startDate}.xlsx`}
        selectedMachine={reportData.lineNo.replace("report", "").toUpperCase()}
        selectedDate={reportData.startDate}
        selectedShift={reportData.shiftId === "1" ? "Day" : "Night"}
      />


      <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table size="small" style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header, index) => (
                  <StyledTableCell key={index}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  {tableHeaders.map((header, colIndex) => (
                    <StyledTableCell key={colIndex}>{row[header]}</StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 1000]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Rows per page:"
      />

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <MuiAlert onClose={() => setOpenSnackbar(false)} severity={severity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
