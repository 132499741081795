import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiMachineMaster } from "../api/api.addmachine";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { apigetMachine } from "../api/apigetmachine";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { apiGetShift } from "../api/api.getshift";
import { useAuthCheck } from "../utils/Auth";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ShiftMaster() {
  const [machinedata, setMachinedata] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [machineID, setMachineID] = useState("");
  const [plantNO, setPlantNO] = useState("");
  const [machineName, setMachineName] = useState("");
  const [machineCode, setMachineCOde] = useState("");
  const [lineProductionCount, setLineProductionCount] = useState("");
  const [lineName, setLineName] = useState("");
  const [severity, setSeverity] = useState("success");
  const [shiftData,setShiftData]=useState([])
  const [error, setError] = useState(null);
  const [refreshData, setRefreshData] = useState(false);

  useAuthCheck()
  useEffect(() => {
    const getmachine = async () => {
      try {
        const result = await apigetMachine();
        console.log(result?.data.data);
        setMachinedata(result?.data.data);
        console.log("machine", machinedata);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    return () => {
      getmachine();
    };
  }, []);
  useEffect(() => {
    const  getShift= async () => {
      try {
        const result = await apiGetShift();
        console.log("shiftdata",result.data.data);
        setShiftData(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      }
    };
    getShift();
  }, [refreshData]);
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handlePlaneNOChange = (event) => {
    setPlantNO(event.target.value);
  };
  const handleMachineIDChange = (event) => {
    setMachineID(event.target.value);
  };
  const handleMachineNameChange = (event) => {
    setMachineName(event.target.value);
  };
  const handleMachineCodeChange = (event) => {
    setMachineCOde(event.target.value);
  };
  const handleLineNameChange = (event) => {
    setLineName(event.target.value);
  };
  const handleAddSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await apiMachineMaster(
        machineCode,
        machineID,
        machineName,
        plantNO,
        lineProductionCount,
        lineName
      );
      setAddOpen(false);
      console.log(
        "mcode,mid,plantno,lpc,linen",
        machineCode,
        machineID,
        machineName,
        plantNO,
        lineProductionCount,
        lineName
      );
      handleSnackbarOpen("Machine added successfully!", "success"); // Pass severity as "success"
      console.log("response", result.data);
    } catch (error) {
      console.error("Error adding machine:", error);
      handleSnackbarOpen("Error adding machine. Please try again.", "error"); // Pass severity as "error"
    }
  };
 

  return (
    <div style={{ padding: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
         margin:'20px 0px 20px 0px '
        }}
      >
        <h2>Shift Master</h2>
    
     
       
        </div>
        <Box>
        <Table
          size="small"
          style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell className="table-cell">
                Plant Name
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Line Name{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Machine Name
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Shift Name
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Shift Start Date
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Shift End Date{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Tea Break Start1{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Tea Break End1{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Tea Break Start2{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Tea Break End2{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Meal Break Start{" "}
              </StyledTableCell>
              <StyledTableCell className="table-cell">
                Meal Break End{" "}
              </StyledTableCell>

              {/* <StyledTableCell  className="table-cell">Line Name</StyledTableCell > */}
              {/* <StyledTableCell className="table-cell">Edit</StyledTableCell>
              <StyledTableCell className="table-cell">Delete</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {shiftData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell className="table-cell">
                  {row.plantName}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.lineName}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.machineName}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.shiftName}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.startTime}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.endTime}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.firstTeaBreakStartTime}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.firstTeaBreakEndTime}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.secondTeaBreakStartTime}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.secondTeaBreakEndTime}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.lunchBreakStartTime}
                </StyledTableCell>
                <StyledTableCell className="table-cell">
                  {row.lunchBreakEndTime}
                </StyledTableCell>

               
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
