import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tab,
  Tabs,
  Box,
  Typography,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { cbmDetailApi } from "../../api/Scoul API/Common/cbmDetail";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  backgroundColor: "#1FAEC5",
  color: "white",
  "&.Mui-selected": {
    backgroundColor: "#1FAEC5",
    color: "white",
  },
  "&.Mui-disabled": {
    backgroundColor: "grey",
    color: "darkgrey",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CBMDetailComponent() {
  const [cbmDetail, setCbmDetail] = useState({
    line1: [],
    line2: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [headMenus, setHeadMenus] = useState([]);

  useEffect(() => {
    fetchCbmDetail();
  }, []);

  const fetchCbmDetail = async () => {
    setLoading(true);
    try {
      const line1Response = await cbmDetailApi.getCBMDetail(2);

      // const line2Response = await cbmDetailApi.getCBMDetail(3);

      setCbmDetail({
        line1: line1Response.data,

        // line2: line2Response.data
        line2: [],
      });
      console.log("line 1 response:", line1Response.data.data);
      setHeadMenus(line1Response.data[0]);
      console.log("head menus:", headMenus);
      setLoading(false);
    } catch (error) {
      console.error("Error getting CBM detail:", error);
      setError("Failed to fetch CBM details");
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const renderTable = (data) => {
    if (!data || data.length === 0) {
      return <Typography>No data available for this line.</Typography>;
    }

    // Remove the first item by slicing the array from index 1
    const filteredData = data.slice(1);
    const emptyRows =
      page > 0
        ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length)
        : 0;
    return (
      <div
        style={{
          padding: "20px",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ flexGrow: 1, overflow: "auto" }}
        >
          <Table stickyHeader aria-label="CBM Detail table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>{headMenus.sNo}</StyledTableCell>
                <StyledTableCell>{headMenus.parameterCode}</StyledTableCell>
                <StyledTableCell>{headMenus.parameterName}</StyledTableCell>
                <StyledTableCell>{headMenus.pointName}</StyledTableCell>
                <StyledTableCell>{headMenus.value1}</StyledTableCell>
                <StyledTableCell>{headMenus.value2}</StyledTableCell>
                <StyledTableCell>{headMenus.value3}</StyledTableCell>
                <StyledTableCell>{headMenus.value4}</StyledTableCell>
                <StyledTableCell>{headMenus.value5}</StyledTableCell>
                <StyledTableCell>{headMenus.value6}</StyledTableCell>

                {/* <StyledTableCell>Fibrizer</StyledTableCell>
                <StyledTableCell>Leveller</StyledTableCell>
                <StyledTableCell>Head On Chopper A</StyledTableCell>
                <StyledTableCell>Head On Chopper B1</StyledTableCell>
                <StyledTableCell>Kicker Cum Chopper A</StyledTableCell>
                <StyledTableCell>Kicker Cum Chopper B1</StyledTableCell> */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredData
              ).map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.sNo}</StyledTableCell>
                  <StyledTableCell>{row.parameterName}</StyledTableCell>
                  <StyledTableCell>{row.parameterCode}</StyledTableCell>
                  <StyledTableCell>{row.pointName}</StyledTableCell>
                  <StyledTableCell
                    style={{
                      background: `${row.alert1}`,
                      color:
                        row.alert1 === "green" || row.alert1 === "red"
                          ? "white"
                          : "black",
                    }}
                  >
                    {row.value1}
                  </StyledTableCell>
                  <StyledTableCell
                    sstyle={{
                      background: `${row.alert2}`,
                      color:
                        row.alert2 === "green" || row.alert2 === "red"
                          ? "white"
                          : "black",
                    }}
                  >
                    {row.value2}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      background: `${row.alert3}`,
                      color:
                        row.alert3 === "green" || row.alert3 === "red"
                          ? "white"
                          : "black",
                    }}
                  >
                    {row.value3}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      background: `${row.alert4}`,
                      color:
                        row.alert4 === "green" || row.alert4 === "red"
                          ? "white"
                          : "black",
                    }}
                  >
                    {row.value4}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      background: `${row.alert5}`,
                      color:
                        row.alert5 === "green" || row.alert5 === "red"
                          ? "white"
                          : "black",
                    }}
                  >
                    {row.value5}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      background: `${row.alert6}`,
                      color:
                        row.alert6 === "green" || row.alert6 === "red"
                          ? "white"
                          : "black",
                    }}
                  >
                    {row.value6}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        height: "calc(100vh - 40px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="CBM Detail Tabs"
        >
          <StyledTab label="Line 1" />
          <StyledTab label="Line 2" disabled={cbmDetail.line2.length === 0} />
        </Tabs>
      </Box>
      <TabPanel
        value={tabValue}
        index={0}
        style={{ flexGrow: 1, overflow: "auto" }}
      >
        {renderTable(cbmDetail.line1)}
      </TabPanel>
      <TabPanel
        value={tabValue}
        index={1}
        style={{ flexGrow: 1, overflow: "auto" }}
      >
        {renderTable(cbmDetail.line2)}
      </TabPanel>
    </Box>
  );
}
