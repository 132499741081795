import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiMachineMaster } from "../api/api.addmachine";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { apigetMachine } from "../api/apigetmachine";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { apiUpdateMachineMaster } from "../api/api.updatemachine";
import { apigetLines } from "../api/api.getline";
import { apiGetPlant } from "../api/api.getplant";
import { apiDeleteMachine } from "../api/api.deletemachine";
import DeleteConfirmationModal from "./deletemodal";
import { Skeleton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuthCheck } from "../utils/Auth";
import { apiGetDashMsg } from "../api/api.getdashboardmessage";
import { dashboardApi } from "../api/dashboard";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Publisher() {
  const [machinedata, setMachinedata] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const [severity, setSeverity] = useState("success");
  const [error, setError] = useState(null);
  const [dashMsg, setdashMsg] = useState([]);

  const [refreshData, setRefreshData] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [deletePublisherId, setDeletePublisherId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [publisherData, setPublisherData] = useState({
    id: "",
    header: "",
    subheader: "",
    contentLine1: "",
    contentLine2: "",
    contentLine3: "",
  });
  const [updateId, setUpdateId] = useState(null);
  useAuthCheck();

  useEffect(() => {
    const getMsg = async () => {
      try {
        const result = await dashboardApi.getDasboardMessage();
        console.log(result.data.data, "MSG data");
        setdashMsg(result.data.data);
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error in Publisher");
      }
    };
    getMsg();
  }, [refreshData]);
  useEffect(() => {
    if (publisherData.id) {
      setUpdateOpen(true);
    }
  }, [publisherData]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting data:", publisherData);

    try {
      const result = await dashboardApi.updateDashboard(
        updateId,
        publisherData
      );
      console.log("API response:", result.data);
      setUpdateOpen(false);
      handleSnackbarOpen("Publisher updated successfully!", "success");
      setRefreshData((prev) => !prev);
      setPublisherData({
        header: "",
        subheader: "",
        contentLine1: "",
        contentLine2: "",
        contentLine3: "",
       
      });
    } catch (error) {
      console.error("Error updating publisher:", error);
      handleSnackbarOpen(
        "Error updating publisher. Please try again.",
        "error"
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPublisherData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditClick = (row) => {
    console.log("row data:", row);
    setUpdateId(row.dashboardMessageID);
    setPublisherData(row)
    // setPublisherData({
    //   id: row.dashboardMessageID,
    //   header: row.line1,
    //   subheader: row.line2,
    //   contentLine1: row.line3,
    //   contentLine2: row.line4,
    //   contentLine3: row.line5,
    // });
    setUpdateOpen(true);
  };

  const handleDeleteClick = (row) => {
    setDeletePublisherId(row.dashboardMessageID);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await dashboardApi.deleteDashboard(deletePublisherId);
      handleSnackbarOpen("Publisher Deleted successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error deleting publisher:", error);
      handleSnackbarOpen(
        "Error deleting publisher. Please try again.",
        "error"
      );
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, machinedata.length - page * rowsPerPage);

  const handleModalClose = () => {
    setPublisherData({
      header: "",
      subheader: "",
      contentLine1: "",
      contentLine2: "",
      contentLine3: "",
    });
    setAddOpen(false);
    setUpdateOpen(false);
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ padding: "0px 20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "20px 0px 20px 0px",
            }}
          >
            <h2>Publisher</h2>

            {/* <Button
              variant="contained"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setAddOpen(true)}
            >
              Add Publisher
            </Button> */}
          </div>

          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Line 1</StyledTableCell>
                <StyledTableCell>Line 2</StyledTableCell>
                <StyledTableCell>Line 3</StyledTableCell>
                <StyledTableCell>Line 4</StyledTableCell>
                <StyledTableCell>Line 5</StyledTableCell>
              
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dashMsg
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={`${row.id}-${index}`}>
                    <StyledTableCell>{row.header}</StyledTableCell>
                    <StyledTableCell>{row.subheader}</StyledTableCell>
                    <StyledTableCell>{row.contentLine1}</StyledTableCell>
                    <StyledTableCell>{row.contentLine2}</StyledTableCell>
                    <StyledTableCell>{row.contentLine3}</StyledTableCell>

                  
                    <StyledTableCell>
                      <IconButton
                        onClick={() => handleEditClick(row)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      {/* <IconButton onClick={() => handleDeleteClick(row, index)}>
                        <DeleteIcon sx={{ color: "red" }} />
                      </IconButton> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dashMsg.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}

      <Modal open={updateOpen} onClose={handleModalClose}>
        <div
          style={{
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            minWidth: "500px",
          }}
        >
          <button
            onClick={handleModalClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "30px",
            }}
          >
            &times;
          </button>
          <h2>Update Publisher</h2>
          <hr />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <FormControl sx={{ width: "26ch" }}>
              <TextField
                name="header"
                label="Line 1"
                value={publisherData?.header}
                onChange={handleInputChange}
              />
            </FormControl>
            <TextField
              name="subheader"
              label="Line 2"
              value={publisherData?.subheader}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <FormControl sx={{ width: "26ch" }}>
              <TextField
                name="contentLine1"
                label="Line 3"
                onChange={handleInputChange}
                value={publisherData?.contentLine1}
              />
            </FormControl>
            <FormControl sx={{ width: "26ch" }}>
              <TextField
                name="contentLine2"
                label="Line 4"
                onChange={handleInputChange}
                value={publisherData?.contentLine2}
              />
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <FormControl sx={{ width: "26ch" }}>
              <TextField
                name="contentLine3"
                label="Line 5"
                value={publisherData?.contentLine3}
                onChange={handleInputChange}
              />
            </FormControl>
          
          </div>
          <Button
            onClick={handleUpdateSubmit}
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
          >
            Update
          </Button>
        </div>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
}
