import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Paper,
  Alert,
  AlertTitle,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { dashboardApi } from "../api/dashboard";
import "../assets/css/smartdashboard.css";
import logo from "../assets/images/adm.png";

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "all 0.3s ease-in-out",
  borderRadius: "10px",
  border: "1px solid gray",
  background: "white",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0px 5px 15px rgba(0,0,0,0.2)",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.darkblue,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 32,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SmartDashboard = () => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [alert, setAlert] = useState("");
  const [furnace2Detail, setFurnace2Detail] = useState(null);
  const [furnace3Detail, setFurnace3Detail] = useState(null);
  const [cracker2Detail, setCracker2Detail] = useState(null);
  const [cracker3Detail, setCracker3Detail] = useState(null);
  const [alertDetail, setAlertDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardMessage, setDashboardMessage] = useState(null);

  const [dateTimes, setDateTimes] = useState({
    furnace2: "",
    furnace3: "",
    cracker2: "",
    cracker3: "",
  });

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const [
          furnace2Res,
          furnace3Res,
          cracker2Res,
          cracker3Res,
          alertRes,
          dashboardMessageRes,
        ] = await Promise.all([
          dashboardApi.getFurnace2Dasboard(),
          dashboardApi.getFurnace3Dasboard(),
          dashboardApi.getCracker2Dasboard(),
          dashboardApi.getCracker3Dasboard(),
          dashboardApi.getAlertMessage(),
          dashboardApi.getDasboardMessage(),
        ]);
        setFurnace2Detail(
          mapData(
            furnace2Res.data.data,
            "furnace2",
            "valueFurnace2",
            "unitFurnace2"
          )
        );
        setFurnace3Detail(
          mapData(
            furnace3Res.data.data,
            "furnace3",
            "valueFurnace3",
            "unitFurnace3"
          )
        );
        setCracker2Detail(
          mapData(
            cracker2Res.data.data,
            "cracker2",
            "valueCracker2",
            "unitCracker2"
          )
        );
        setCracker3Detail(
          mapData(
            cracker3Res.data.data,
            "cracker3",
            "valueCracker3",
            "unitCracker3"
          )
        );
        setDateTimes({
          furnace2: extractDateTime(furnace2Res.data.data, "furnace2"),
          furnace3: extractDateTime(furnace3Res.data.data, "furnace3"),
          cracker2: extractDateTime(cracker2Res.data.data, "cracker2"),
          cracker3: extractDateTime(cracker3Res.data.data, "cracker3"),
        });
        setAlertDetail(alertRes.data.data);
        setDashboardMessage(dashboardMessageRes.data.data[0]);
      } catch (err) {
        setError(err.message || "An error occurred while fetching details.");
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
    const intervalId = setInterval(() => {
      fetchDetails(); 
    }, 30000);
  
  
    return () => clearInterval(intervalId);
  }, []);
console.log("alert message:",alertDetail)
  const mapData = (data, key, valueKey, unitKey) => {
    const mappedData = {};
    data.forEach((item) => {
      mappedData[item[key]] = {
        value: parseFloat(item[valueKey]),
        unit: item[unitKey],
      };
    });
    return mappedData;
  };

  const extractDateTime = (data, key) => {
    const dateTimeItem = data.find((item) => item[key] === "Date Time");
    return dateTimeItem
      ? dateTimeItem[`value${capitalizeFirstLetter(key)}`]
      : "";
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const DashboardMessageScreen = ({ message }) => {
    if (!message) return null;


    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Logo"
          sx={{
            position: "absolute",
            top: 20,
            left: 20,
            width: 100,
            height: "auto",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            textAlign: "center",
            p: 3,
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: 60, fontWeight: "bold", mb: 2, color: "#49013d" }}
          >
            {message.header}
          </Typography>
          {message.subheader && (
            <Typography
              variant="h2"
              sx={{ fontSize: 60, mb: 2, color: "#49013d" }}
            >
              {message.subheader}
            </Typography>
          )}
          {message.contentLine1 && (
            <Typography
              variant="h3"
              sx={{ fontSize: 60, mb: 1, color: "#49013d" }}
            >
              {message.contentLine1}
            </Typography>
          )}
          {message.contentLine2 && (
            <Typography
              variant="h3"
              sx={{ fontSize: 60, mb: 1, color: "#49013d" }}
            >
              {message.contentLine2}
            </Typography>
          )}
          {message.contentLine3 && (
            <Typography
              variant="h3"
              sx={{ fontSize: 60, mb: 1, color: "#49013d" }}
            >
              {message.contentLine3}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  const screens = [
    {
      title: "Furnace 2",
      data: furnace2Detail,
      dateTime: dateTimes.furnace2,
    },
    {
      title: "Cracker 2",
      data: cracker2Detail,
      dateTime: dateTimes.cracker2,
    },
    {
      title: "Furnace 3",
      data: furnace3Detail,
      dateTime: dateTimes.furnace3,
    },
    {
      title: "Cracker 3",
      data: cracker3Detail,
      dateTime: dateTimes.cracker3,
    },
    {
      title: "Dashboard Message",
      component: <DashboardMessageScreen message={dashboardMessage} />,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentScreen((prev) => (prev + 1) % 5);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  const getMachineName = (screenIndex) => {
    switch (screenIndex) {
      case 0:
        return "furnace2";
      case 1:
        return "cracker2";
      case 2:
        return "furnace3";
      case 3:
        return "cracker3";
      default:
        return null;
    }
  };

  const filterAlerts = (alerts, screenIndex) => {
    const machineName = getMachineName(screenIndex);
    if (!machineName) return [];
    return alerts.filter((alert) =>
      alert.machine_name.toLowerCase().includes(machineName)
    );
  };

  const filteredAlerts = alertDetail.filter(alert => {
    return alert.machine_name === screens[currentScreen]?.title;
  });
  
  console.log("filtered alerts:",filteredAlerts)

  return (
    <Box sx={{ flexGrow: 1, p: 3, bgcolor: "#f0f2f5" }}>
      {loading && <LinearProgress />}
      {currentScreen !== 4 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding:'2px 5px 2px 5px',
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: "#ffffff",
              backgroundImage: "linear-gradient(56deg, #2a2a72 0%, #7fc9f7)",
            }}
          >
            <Typography
             
              sx={{ color: "white", fontSize: "40px", fontWeight: "bold" }}
            >
              {screens[currentScreen]?.title}
            </Typography>
            <Typography
              sx={{ color: "white", fontSize: "40px", fontWeight: "bold" }}
            >
              {screens[currentScreen]?.dateTime}
            </Typography>
          </Paper>
        </Box>
      )}

      {screens[currentScreen]?.data ? (
        <Grid container spacing={3}>
          {Object.entries(screens[currentScreen].data)
            .slice(0, -1)
            .map(([key, { value, unit }]) => (
              <Grid item xs={12} sm={6} md={4} lg={2.4} key={key}>
                <StyledCard>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h2"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        fontSize: "35px",
                        textAlign: "center",
                        color: "#1a237e",
                      }}
                    >
                      {key} ({unit})
                    </Typography>
                    <hr />
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: "bold",
                        color: "#49013d",
                        fontSize: "60px",
                        textAlign: "center",
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 55px 10px 55px",
                      }}
                    >
                      {value}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
        </Grid>
      ) : (
        screens[currentScreen]?.component
      )}
    {currentScreen !== 4 && alertDetail.length > 0&& (
      <Box mt={3} sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            boxShadow: 3,
            border: "1px solid #ccc",
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: "32px",
              fontWeight: "bold",
              padding:'2px 5px 2px 5px',
              backgroundColor: "#ffffff",
              backgroundImage: "linear-gradient(56deg, #2a2a72 0%, #7fc9f7)",
              color: "white",
            }}
          >
            Alert
          </Typography>
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow sx={{ background: "#7f016fdb" }}>
                  <StyledTableCell
                    sx={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Machine Name
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Parameter Name
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Value
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Message
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alertDetail.map((alert, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell
                      sx={{
                        fontSize: "40px",
                        borderRight: "1px solid #ccc",
                        fontWeight: "bold",
                        padding:'10px'
                      }}
                    >
                      {alert.machine_name}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: "40px",
                        borderRight: "1px solid #ccc",
                        fontWeight: "bold",
                        padding:'10px'

                      }}
                    >
                      {alert.parameter_name}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: "40px",
                        borderRight: "1px solid #ccc",
                        fontWeight: "bold",
                        background:'red',color:'white',
                        padding:'10px'

                      }}
                    >
                      {alert.value}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: "40px",
                        borderRight: "1px solid #ccc",
                        fontWeight: "bold",
                        padding:'10px'

                      }}
                    >
                      {alert.message}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    )}
      {error && (
        <Alert severity="error" variant="filled" sx={{ mt: 3 }}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default SmartDashboard;
