import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    whiteSpace: 'nowrap',
    background:'#1FAEC5',
    color:'white'
  }));
  
  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: 400, 
    overflow: 'auto',
    '& .MuiTableHead-root': {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));
const SoftwareReportTable = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
   
    const fetchData = async () => {
   
      const response = await fetch('your-api-endpoint');
      const data = await response.json();
      setTableData(data);
    };

    fetchData();
  }, []);

  return (
    <TableContainer component={Paper} style={{padding:'10px'}}>
      <Box sx={{ p: 2, backgroundColor: '#1976d2', color: 'white' }}>

        <Box display="flex" justifyContent="space-between">
          <TextField label="PTPL Order No:" variant="filled" size="small" sx={{ backgroundColor: 'white', mr: 1 }} />
          <TextField label="Date:" variant="filled" size="small" sx={{ backgroundColor: 'white', mr: 1 }} />
          <TextField label="Shift Gen:" variant="filled" size="small" sx={{ backgroundColor: 'white' }} />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <TextField label="Customer:" variant="filled" size="small" sx={{ backgroundColor: 'white', mr: 1, flexGrow: 1 }} />
          <TextField label="Gearbox Box Sr. No:" variant="filled" size="small" sx={{ backgroundColor: 'white', flexGrow: 1 }} />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <TextField label="Unit details:" variant="filled" size="small" sx={{ backgroundColor: 'white', mr: 1 }} />
          <TextField label="Ratio:" variant="filled" size="small" sx={{ backgroundColor: 'white', mr: 1 }} />
          <TextField label="FLS/Pump Details-Make:" variant="filled" size="small" sx={{ backgroundColor: 'white', mr: 1 }} />
          <TextField label="Capacity:" variant="filled" size="small" sx={{ backgroundColor: 'white', mr: 1 }} />
          <TextField label="Drawing No:" variant="filled" size="small" sx={{ backgroundColor: 'white' }} />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <TextField label="Testing started at:" variant="filled" size="small" sx={{ backgroundColor: 'white', mr: 1, flexGrow: 1 }} />
          <TextField label="Testing stopped at:" variant="filled" size="small" sx={{ backgroundColor: 'white', flexGrow: 1 }} />
        </Box>
      </Box>

      <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <StyledTableContainer component={Paper}>
        <Table stickyHeader sx={{ minWidth: 2000 }} size="small" aria-label="software report table">
          <TableHead>
            <TableRow>
              <StyledTableCell rowSpan={2}>Sr. No</StyledTableCell>
              <StyledTableCell rowSpan={2}>Time in Hr</StyledTableCell>
              <StyledTableCell rowSpan={2}>Machine No</StyledTableCell>
              <StyledTableCell rowSpan={2}>Input Speed</StyledTableCell>
              <StyledTableCell rowSpan={2}>Output Ratio</StyledTableCell>
              <StyledTableCell rowSpan={2}>Actual</StyledTableCell>
              <StyledTableCell colSpan={3}>Vibration at input side (4.5mm/sec Max)</StyledTableCell>
              <StyledTableCell colSpan={3}>Vibration at input side (4.5mm/sec Max)(opposite)</StyledTableCell>
              <StyledTableCell colSpan={3}>Vibration at output side (4.5mm/sec Max)</StyledTableCell>
              <StyledTableCell colSpan={3}>Vibration at output side (4.5mm/sec Max)(opposite)</StyledTableCell>
              <StyledTableCell rowSpan={2}>
                <Typography variant="caption">NOISE LEVEL (85dBA max at 1mtr dist.</Typography>
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>
                <Typography variant="caption">AMB. TEMP. (°C)</Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={8}>Bearing Cover Temp in (°C) Nearer to bearing</StyledTableCell>
              <StyledTableCell rowSpan={2}>
                <Typography variant="caption">Body Temp (°C) Input Side</Typography>
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>
                <Typography variant="caption">Oil Temp (°C)</Typography>
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>
                <Typography variant="caption">Oil Temp Rise (45°C max)</Typography>
              </StyledTableCell>
              <StyledTableCell rowSpan={2}>
                <Typography variant="caption">Result (OK/ NOT OK)</Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              {/* Vibration subheaders */}
              {['Vertical mm/sec', 'Horizontal mm/sec', 'Axial mm/sec'].map((header, index) => (
                <React.Fragment key={index}>
                  <StyledTableCell>
                    <Typography variant="caption">{header}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="caption">{header}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="caption">{header}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="caption">{header}</Typography>
                  </StyledTableCell>
                </React.Fragment>
              ))}
              {/* Bearing Cover Temp subheaders */}
              {['I/P', 'O/P'].map((header) => (
                <React.Fragment key={header}>
                  <StyledTableCell>
                    <Typography variant="caption">{header} side</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="caption">{header} side</Typography>
                  </StyledTableCell>
                </React.Fragment>
              ))}
              {['Int II stage', 'Int III stage'].map((header) => (
                <React.Fragment key={header}>
                  <StyledTableCell>
                    <Typography variant="caption">{header}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="caption">{header}</Typography>
                  </StyledTableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <StyledTableCell>{row.srNo}</StyledTableCell>
                <StyledTableCell>{row.timeInHr}</StyledTableCell>
                <StyledTableCell>{row.machineNo}</StyledTableCell>
                <StyledTableCell>{row.inputSpeed}</StyledTableCell>
                <StyledTableCell>{row.outputRatio}</StyledTableCell>
                <StyledTableCell>{row.actual}</StyledTableCell>
                {/* Add more StyledTableCell components here for all columns */}
                {/* You'll need to add cells for all vibration data, noise level, temperatures, etc. */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>

      {/* Leakage Area */}
      <Box sx={{ p: 2, borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Typography variant="subtitle1">Leakage Area:</Typography>
        <Box display="flex" justifyContent="space-between">
          <TextField label="1) Leakage from Oil seal - Yes/No" variant="outlined" size="small" fullWidth sx={{ mr: 1 }} />
          <TextField label="2) Leakage from cover/catcher face - Yes/No" variant="outlined" size="small" fullWidth sx={{ mr: 1 }} />
          <TextField label="3) Leakage from cojoint face - Yes/No" variant="outlined" size="small" fullWidth sx={{ mr: 1 }} />
          <TextField label="4) Leakage from breather - Yes/No" variant="outlined" size="small" fullWidth />
        </Box>
      </Box>

      {/* Manual Inspection Entry */}
      <Box sx={{ p: 2, borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Typography variant="subtitle1">Manual Inspection Entry:</Typography>
        <TextField label="Remark if Any:" variant="outlined" size="small" fullWidth sx={{ mb: 1 }} />
        <TextField label="RHB (HOLD BACK) Noise: Yes/No" variant="outlined" size="small" fullWidth sx={{ mb: 1 }} />
        <TextField label="NO LOAD TESTING Result: Accepted / Rejected" variant="outlined" size="small" fullWidth sx={{ mb: 1 }} />
        <TextField label="QA Components: Release of product" variant="outlined" size="small" fullWidth sx={{ mb: 1 }} />
        <Box display="flex" justifyContent="space-between">
          <TextField label="Prepared by:" variant="outlined" size="small" sx={{ flexGrow: 1, mr: 1 }} />
          <TextField label="Verified by:" variant="outlined" size="small" sx={{ flexGrow: 1 }} />
        </Box>
      </Box>
    </TableContainer>
  );
};

export default SoftwareReportTable;