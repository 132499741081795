import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Button,
  Grid,
  tableCellClasses,
  styled,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { admRawData } from "../api/api.admRawDataMachine";
import DownloadReport from "../utils/DownloadReport";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
};

export default function ADMRawData() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [rawData, setRawData] = useState({
    machineId: "",
    startDate: getCurrentDate(),
    endDate: getCurrentDate(),
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRawData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRawData = () => {
    const formatDate = (date) => {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Date(date)
        .toLocaleDateString("en-GB", options)
        .replace(/ /g, "-");
    };

    const apiCall = async () => {
      const response = await admRawData.getRawData({
        machineId: rawData.machineId,
        startDate: formatDate(rawData.startDate),
        endDate: formatDate(rawData.endDate),
      });
      console.log("API response in handleRawData:", response);
      return response;
    };
    const formatData = (data) => {
      if (!data || data.length === 0) return [];

      const headers = Object.keys(data[0]).map(key => data[0][key]);
      const rows = data.slice(1).map(item => Object.values(item));

      return [headers, ...rows];
    };

    return { apiCall, formatData, fileName: "RawData.xlsx" };
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { apiCall, formatData } = handleRawData();
      const response = await apiCall();

      if (response && response.data) {
        const formattedData = formatData(response.data);
        setData(formattedData);
        handleSnackbarOpen("Raw Data fetched successfully!", "success");
      } else {
        handleSnackbarOpen("No data available from the response", "info");
      }
    } catch (error) {
      console.error("Error fetching ADM raw data:", error);
      handleSnackbarOpen("Error fetching raw data. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ padding: "0px 20px", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          margin: "20px 0px 20px 0px",
        }}
      >
        <h2>Raw Data</h2>
      </div>
      <Grid
        container
        spacing={2}
        style={{ width: "100%", alignItems: "center", marginBottom: "10px" }}
      >
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel>Select Device</InputLabel>
            <Select
              name="machineId"
              value={rawData.machineId}
              onChange={handleInputChange}
            >
              <MenuItem value="1">Furnace 2</MenuItem>
              <MenuItem value="2">Furnace 3</MenuItem>
              <MenuItem value="3">Cracker 2</MenuItem>
              <MenuItem value="4">Cracker 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="Start Date"
              name="startDate"
              type="date"
              value={rawData.startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{ minWidth: 250 }}>
            <TextField
              label="End Date"
              name="endDate"
              type="date"
              value={rawData.endDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleAddSubmit}>
            OK
          </Button>
        </Grid>
      </Grid>
      <DownloadReport {...handleRawData()} />

      <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}>
            <TableHead>
            <TableRow>
                {data[0] && data[0].map((header, index) => (
                  <StyledTableCell key={index}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(1).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <StyledTableCell key={cellIndex}>{cell}</StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}