
import React, { useState, useEffect } from "react";
import "../assets/css/navbar.css";
import {
  faBars,
  faClose,
  faAngleDown,
  faAngleUp,
  faGear,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Button, SwipeableDrawer } from "@mui/material";
import blogo from "../assets/images/digineous2.png";

function NavBar() {
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [plantArchitectureOpen, setPlantArchitectureOpen] = useState(false);

  const [UATOpen, setUATOpen] = useState(false);
  const [rawDataOpen, setRawDataOpen] = useState(false);
  const [productionOpen, setProductionOpen] = useState(false);
  const [method2Open, setMethod2Open] = useState(false);
  const [method1Open, setMethod1Open] = useState(false);
  const [report1, setReport1Open] = useState(false);

  const [report2, setReport2Open] = useState(false);
  const [userId, setUserId] = useState(null);

  const [state, setState] = React.useState({
    right: false,
  });

  const navigate = useNavigate();

  const handlePlantArchitectureClick = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);
    setRawDataOpen(false);
    setPlantArchitectureOpen(!plantArchitectureOpen);
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("userID");
    setUserId(storedUserId);
  }, []);

  const handleReport2Click = () => {
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);

    setRawDataOpen(false);
    setPlantArchitectureOpen(false);
    setProductionOpen(false);
    setReport2Open(!report2);
  };
  const handleRawDataClick = () => {
    setRawDataOpen(!rawDataOpen);
    setUATOpen(false);
    setMethod2Open(false);
    setMethod1Open(false);
    setProductionOpen(false);

    setPlantArchitectureOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };
  const userID = localStorage.getItem("userID");
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    navigate("/login");
  };
  const handleHideMenu = () => {
    setHideMenu(!hideMenu);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSettingsClick = () => {
    setOpen(!open);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ right: open });
  };

  const renderMenuItems = () => {
    if (userId === "adm_superadmin") {
      return (
        <>
          <ListItem
            onClick={toggleDrawer(false)}
            Button
            component={Link}
            to="/dashboard"
            className={
              location.pathname === "/dashboard" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            onClick={toggleDrawer(false)}
            Button
            component={Link}
            to="/smart_dashboard"
            className={
              location.pathname === "/smart_dashboard" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Smart Dashboard" />
          </ListItem>
          <ListItem
            style={{ cursor: "pointer" }}
            Button
            onClick={handleSettingsClick}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ cursor: "pointer" }}
                Button
                onClick={handlePlantArchitectureClick}
              >
                <ListItemText primary="Plant Architecture" />
                {plantArchitectureOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={plantArchitectureOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/Plant_master"
                    className={
                      location.pathname === "/plant_architecture/Plant_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Plant Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/linemaster"
                    className={
                      location.pathname === "plant_architecture/linemaster"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Line Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/machine_master"
                    className={
                      location.pathname === "/plant_architecture/machine_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Machine Master" />
                  </ListItem>

                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/devicemaster"
                    className={
                      location.pathname === "/plant_architecture/devicemaster"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Device Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/thresholds"
                    className={
                      location.pathname === "/plant_architecture/thresholds"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Threshold" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/production_plan/shift_master"
                    className={
                      location.pathname === "/production_plan/shift_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Shift Master" />
                  </ListItem>
              
                </List>
              </Collapse>
            </List>
          </Collapse>

          <ListItem
            onClick={toggleDrawer(false)}
            Button
            component={Link}
            to="/adm/rawdata"
            className={
              location.pathname === "/adm/rawdata" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Raw Data" />
          </ListItem>

          <ListItem
            onClick={toggleDrawer(false)}
            Button
            component={Link}
            to="/admreports"
            className={
              location.pathname === "/admreports" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>

          <ListItem
            onClick={toggleDrawer(false)}
            Button
            component={Link}
            to="/tbm"
            className={location.pathname === "/tbm" ? "activeListItem" : ""}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="TBM" />
          </ListItem>

          <ListItem
            onClick={toggleDrawer(false)}
            Button
            component={Link}
            to="/publishers"
            className={
              location.pathname === "/publishers" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Publisher" />
          </ListItem>
          <ListItem
                    style={{ marginBottom: "20px" }}
                    Button
                    component={Link}
                    to="/device_status"
                    className={
                      location.pathname === "/device_status"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Device Status" />
                  </ListItem>
        </>
      );
    } else if (userId === "scoul_superadmin") {
      return (
        <>
          <ListItem
            Button
            component={Link}
            to="/scoul/cbmdetail"
            className={
              location.pathname === "/scoul/cbmdetail" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            Button
            component={Link}
            to="/scoul/powergraphs"
            className={
              location.pathname === "/scoul/powergraphs" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="CBM & Power" />
          </ListItem>
          <ListItem
            Button
            component={Link}
            to="/scoul/cockpitview"
            className={
              location.pathname === "/scoul/cockpitview" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Cockpit View" />
          </ListItem>
          <ListItem
        style={{ cursor: "pointer" }}
        Button
        onClick={handleSettingsClick}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ cursor: "pointer" }}
                Button
                onClick={handlePlantArchitectureClick}
              >
                <ListItemText primary="Plant Architecture" />
                {plantArchitectureOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={plantArchitectureOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/Plant_master"
                    className={
                      location.pathname === "/plant_architecture/Plant_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Plant Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/linemaster"
                    className={
                      location.pathname === "plant_architecture/linemaster"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Line Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/machine_master"
                    className={
                      location.pathname === "/plant_architecture/machine_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Machine Master" />
                  </ListItem>

                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/devicemaster"
                    className={
                      location.pathname === "/plant_architecture/devicemaster"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Device Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/thresholds"
                    className={
                      location.pathname === "/plant_architecture/thresholds"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Threshold" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/production_plan/shift_master"
                    className={
                      location.pathname === "/production_plan/shift_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Shift Master" />
                  </ListItem>
                  <ListItem
                    style={{ marginBottom: "20px" }}
                    Button
                    component={Link}
                    to="/plant_architecture/device_status"
                    className={
                      location.pathname === "/plant_architecture/device_status"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Device Status" />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Collapse>
          <ListItem
            Button
            component={Link}
            to="/scoul/cbmdetail"
            className={
              location.pathname === "/scoul/cbmdetail" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="CBM Report" />
          </ListItem>
          <ListItem
            Button
            component={Link}
            to="/scoul_reports"
            className={
              location.pathname === "/scoul_reports" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Power Reports" />
          </ListItem>
        </>
      );
    }else if (userId === "gayatree_polymers" || userId==="aura_laser"){
      return (
        <>
          <ListItem
            Button
            component={Link}
            to="/common/emsdashboard"
            className={
              location.pathname === "/common/emsdashboard" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            Button
            component={Link}
            to="/common/rawdata"
            className={
              location.pathname === "/common/rawdata" ? "activeListItem" : ""
            }
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Raw Data" />
          </ListItem>
         
          <ListItem
        style={{ cursor: "pointer" }}
        Button
        onClick={handleSettingsClick}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText  primary="Settings" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ cursor: "pointer" }}
                Button
                onClick={handlePlantArchitectureClick}
              >
                <ListItemText primary="Plant Architecture" />
                {plantArchitectureOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={plantArchitectureOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/Plant_master"
                    className={
                      location.pathname === "/plant_architecture/Plant_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Plant Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/linemaster"
                    className={
                      location.pathname === "plant_architecture/linemaster"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Line Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/machine_master"
                    className={
                      location.pathname === "/plant_architecture/machine_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Machine Master" />
                  </ListItem>

                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/devicemaster"
                    className={
                      location.pathname === "/plant_architecture/devicemaster"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Device Master" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/plant_architecture/thresholds"
                    className={
                      location.pathname === "/plant_architecture/thresholds"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Threshold" />
                  </ListItem>
                  <ListItem
                    Button
                    component={Link}
                    to="/production_plan/shift_master"
                    className={
                      location.pathname === "/production_plan/shift_master"
                        ? "activeListItem"
                        : ""
                    }
                  >
                    <ListItemText primary="Shift Master" />
                  </ListItem>
              
                </List>
              </Collapse>
            </List>
          </Collapse>
          
        </>
      );
    }

    return null;
  };
  return (
    <nav>
      <div>
        <Link className="clogo" to="#">
          <img
            src={blogo}
            alt="Customer Logo"
            style={{ width: "140px", height: "45px" }}
          />
        </Link>
      </div>
      <div className="seticon">
        <FontAwesomeIcon
          style={{ fontSize: "25px", padding: "0px 40px", cursor: "pointer" }}
          icon={faBars}
          onClick={toggleDrawer(true)}
        />
      </div>
      <SwipeableDrawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className={`sidebar ${state.right ? "open" : ""}`}>
          <List>
            {renderMenuItems()}
            <ListItem
              Button
              onClick={handleLogout}
              style={{ cursor: "pointer" }}
              className={
                location.pathname === "/logout" ? "activeListItem" : ""
              }
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOut} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </nav>
  );
}
export default NavBar;
