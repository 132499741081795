import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, Paper, Typography, Modal } from "@mui/material";
import { Maximize2 } from "lucide-react";
import LineChart from "../Graphs/LineChart";
import BarChart from "../Graphs/BarChart";
import RadialBarChart from "../Graphs/RadialBarChart";
import RadialBarChart2 from "../Graphs/RadialChart2";
import { EMSDashboardData } from "../../api/EMS API/getdashboarddata";
import '../../assets/css/emsdashboard.css';

const ChartModal = ({ open, handleClose, title, children }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="chart-modal-title"
    aria-describedby="chart-modal-description"
  >
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: '80%',
      bgcolor: 'rgba(3, 3, 62, 0.9)',
      border: '4px solid white',
      boxShadow: 24,
      p: 4,
      borderRadius:'20px'
    }}>
      <Typography id="chart-modal-title" variant="h4" component="h2" color="white">
        {title}
      </Typography>
      <Box sx={{ mt: 2, height: 'calc(100% - 60px)' }}>
        {children}
      </Box>
    </Box>
  </Modal>
);

export default function EMSDashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [activeEnergy, setActiveEnergy] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

 

  const fetchEnergyMeasurementsData = useCallback(async () => {
    try {
      const response = await EMSDashboardData.getEMSDasboardData();
      console.log("electrical measurement data:", response.data);
      setDashboardData(response.data);
    } catch (error) {
      console.error("Error fetching energy data:", error);
    }
  }, []);

  const fetchDailyConsumptionData = useCallback(async () => {
    try {
      const response = await EMSDashboardData.getDailyConsumptionPerDay();
      console.log("daily consumption data:", response.data);
      setActiveEnergy(response.data);
    } catch (error) {
      console.error("error getting daily consumption data:", error);
    }
  }, []);
  useEffect(() => {
    fetchEnergyMeasurementsData();
    fetchDailyConsumptionData();
    const interval = setInterval(() => {
      fetchEnergyMeasurementsData();
      fetchDailyConsumptionData();
    }, 30000);
    return () => clearInterval(interval);
  }, [fetchEnergyMeasurementsData, fetchDailyConsumptionData]);

  const getLatestReading = useCallback(() => {
    return dashboardData.length > 0 ? dashboardData[0] : null;
  }, [dashboardData]);

  const getLastReading = useCallback(() => {
    return activeEnergy.length > 0 ? activeEnergy[activeEnergy.length - 1] : null;
  }, [activeEnergy]);
  const parseCustomDate = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');
    const monthIndex = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].indexOf(month.toLowerCase());
    return new Date(year, monthIndex, day);
  };

  const calculateTodaysTotalConsumption = useCallback(() => {
    const now = new Date();
    const today = now.toISOString().split('T')[0];
    console.log("Today's date:", today);
    console.log("Active Energy data:", activeEnergy);
    
    const todayData = activeEnergy.filter(item => {
      const [itemDate] = item.dateTime.split(' ');
      const [day, month, year] = itemDate.split('-');
      const monthIndex = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].indexOf(month.toLowerCase());
      const formattedDate = `${year}-${String(monthIndex + 1).padStart(2, '0')}-${day}`;
      return formattedDate === today;
    });
    console.log("Today's data:", todayData);
    
    if (todayData.length === 0) {
      console.log("No data found for today");
      return "0.00";
    }
    
    const total = todayData.reduce((sum, item) => {
      const energy = parseFloat(item.activeEnergy);
      return sum + (isNaN(energy) ? 0 : energy);
    }, 0);
    
    console.log("Calculated total:", total);
    
    return total.toFixed(2);
  }, [activeEnergy]);
  const latestReading = getLatestReading();
  const lastReading = getLastReading();
  const todaysTotalConsumption = calculateTodaysTotalConsumption();
  console.log("todays consumption:",todaysTotalConsumption)

  const boxData = useMemo(() => [
    { heading: "Total Voltage", value: latestReading ? `${(parseFloat(latestReading.voltageR) + parseFloat(latestReading.voltageY) + parseFloat(latestReading.voltageB)).toFixed(2)} V` : "N/A", color: "grey.200", height: 100 },
    { heading: "Total Current", value: latestReading ? `${(parseFloat(latestReading.currentR) + parseFloat(latestReading.currentY) + parseFloat(latestReading.currentB)).toFixed(2)} A` : "N/A", color: "grey.300", height: 100 },
    { heading: "Total Energy Consumed", value: latestReading ? `${parseFloat(latestReading.activeEnergy).toFixed(2)} kWh` : "N/A", color: "grey.400", height: 100 },
    { heading: "Power Factor", value: latestReading ? `${parseFloat(latestReading.powerFactor).toFixed(2)}` : "N/A", color: "grey.400", height: 100 },
    { heading: "Today's Consumption", value: `${todaysTotalConsumption} kWh`, color: "grey.400", height: 100 },
  ], [latestReading, todaysTotalConsumption]);


  const getChartData = useCallback((dataKey, limit = 5, useTime = false) => {
    return dashboardData.slice(0, limit).map(reading => ({
      name: reading.dateTime.split(' ')[1],
      value: parseFloat(reading[dataKey])
    })).reverse();
  }, [dashboardData]);

  const prepareActiveEnergyData = useCallback(() => {
    return activeEnergy.map(item => {
      const time = item.dateTime.split(' ')[1];
      return {
        name: time,
        value: parseFloat(item.activeEnergy)
      };
    }).sort((a, b) => new Date(a.name) - new Date(b.name));
  }, [activeEnergy]);
  
 const handleOpenModal = useCallback((title, chartComponent) => {
    setModalContent({ title,chartComponent  });
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setModalContent(null);
  }, []);


  const renderChart = useCallback((chartId) => {
    switch(chartId) {
      case 'perDayConsumption':
        return (
          <BarChart
            data={prepareActiveEnergyData().map(d => d.value)}
            labels={prepareActiveEnergyData().map(d => d.name)}
            title="Per Hour Consumption(kWh)"
            xAxisLabel="Hour"
            yAxisLabel="KWH"
          />
        );
      case 'voltage':
        return (
          <LineChart
            data={[
              { name: "R", data: getChartData('voltageR', 5, true).map(d => d.value) },
              { name: "Y", data: getChartData('voltageY', 5, true).map(d => d.value) },
              { name: "B", data: getChartData('voltageB', 5, true).map(d => d.value) },
            ]}
            labels={getChartData('voltageR', 5, true).map(d => d.name)}
            title=" "
            xAxisLabel="Time"
            yAxisLabel="Voltage"
          />
        );
      case 'current':
        return (
          <LineChart
            data={[
              { name: "R", data: getChartData('currentR', 5, true).map(d => d.value) },
              { name: "Y", data: getChartData('currentY', 5, true).map(d => d.value) },
              { name: "B", data: getChartData('currentB', 5, true).map(d => d.value) },
            ]}
            labels={getChartData('currentR', 5, true).map(d => d.name)}
            title=" "
            xAxisLabel="Time"
            yAxisLabel="Current"
          />
        );
      case 'importExport':
        return (
          <>
            <LineChart
              data={[
                { name: "Import", data: getChartData('importEnergy', 5, true).map(d => d.value) },
                { name: "Export", data: getChartData('exportEnergy', 5, true).map(d => d.value) },
              ]}
              labels={getChartData('importEnergy', 5, true).map(d => d.name)}
              title=" "
              xAxisLabel="Time"
              yAxisLabel="Power"
            />
            <Typography
              sx={{
                color: 'white',
                fontWeight: 'bold',
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '5px 10px',
                borderRadius: '5px',
              }}
            >
              Loss: {getLatestReading() ? parseFloat(getLatestReading().importVsExportEnergy).toFixed(2) : 'N/A'} kWh
            </Typography>
          </>
        );
      case 'thdCurrent':
        return (
          <LineChart
            data={[
              { name: "R", data: getChartData('currentThdR', 5, true).map(d => d.value) },
              { name: "Y", data: getChartData('currentThdY', 5, true).map(d => d.value) },
              { name: "B", data: getChartData('currentThdB', 5, true).map(d => d.value) },
            ]}
            labels={getChartData('currentThdR', 5, true).map(d => d.name)}
            title=" "
            xAxisLabel="Time" 
            yAxisLabel="Harmonic %"
          />
        );
      case 'thdVoltage':
        return (
          <LineChart
            data={[
              { name: "R", data: getChartData('voltageThdR', 5, true).map(d => d.value) },
              { name: "Y", data: getChartData('voltageThdY', 5, true).map(d => d.value) },
              { name: "B", data: getChartData('voltageThdB', 5, true).map(d => d.value) },
            ]}
            labels={getChartData('voltageThdR', 5, true).map(d => d.name)}
            title=" "
            xAxisLabel="Time"
            yAxisLabel="Harmonic %"
          />
        );
      default:
        return null;
    }
  }, [getChartData, prepareActiveEnergyData, getLatestReading]);
  const chartData = [
    {
      title: "Per Hour Consumption(kWh)",
      chart: (
        <BarChart
          data={prepareActiveEnergyData().map(d => d.value)}
          labels={prepareActiveEnergyData().map(d => d.name)}
          title="Per Hour Consumption(kWh)"
          xAxisLabel="Time"
          yAxisLabel="KWH"
        />
      ),
      md: 4
    },
    {
      title: "Voltage (V)",
      chart: (
        <LineChart
          data={[
            { name: "R", data: getChartData('voltageR', 5, true).map(d => d.value) },
            { name: "Y", data: getChartData('voltageY', 5, true).map(d => d.value) },
            { name: "B", data: getChartData('voltageB', 5, true).map(d => d.value) },
          ]}
          labels={getChartData('voltageR', 5, true).map(d => d.name)}
          title=" "
          xAxisLabel="Time"
          yAxisLabel="Voltage"
        />
      ),
      md: 4
    },
    {
      title: "Current (A)",
      chart: (
        <LineChart
          data={[
            { name: "R", data: getChartData('currentR', 5, true).map(d => d.value) },
            { name: "Y", data: getChartData('currentY', 5, true).map(d => d.value) },
            { name: "B", data: getChartData('currentB', 5, true).map(d => d.value) },
          ]}
          labels={getChartData('currentR', 5, true).map(d => d.name)}
          title=" "
          xAxisLabel="Time"
          yAxisLabel="Current"
        />
      ),
      md: 4
    },
    {
      title: "Import vs Export",
      chart: (
        <>
          <LineChart
            data={[
              { name: "Import", data: getChartData('importEnergy', 5, true).map(d => d.value) },
              { name: "Export", data: getChartData('exportEnergy', 5, true).map(d => d.value) },
            ]}
            labels={getChartData('importEnergy', 5, true).map(d => d.name)}
            title=" "
            xAxisLabel="Time"
            yAxisLabel="Power"
          />
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold',
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: '5px 10px',
              borderRadius: '5px',
            }}
          >
            Loss: {latestReading ? parseFloat(latestReading.importVsExportEnergy).toFixed(2) : 'N/A'} kWh
          </Typography>
        </>
      ),
    },
    {
      title: "Harmonic Current (%)",
      chart: (
        <LineChart
          data={[
            { name: "R", data: getChartData('currentThdR', 5, true).map(d => d.value) },
            { name: "Y", data: getChartData('currentThdY', 5, true).map(d => d.value) },
            { name: "B", data: getChartData('currentThdB', 5, true).map(d => d.value) },
          ]}
          labels={getChartData('currentThdR', 5, true).map(d => d.name)}
          title=" "
          xAxisLabel="Time" 
          yAxisLabel="THD %"
        />
      ),
    },
    {
      title: "Harmonic Voltage (%)",
      chart: (
        <LineChart
          data={[
            { name: "R", data: getChartData('voltageThdR', 5, true).map(d => d.value) },
            { name: "Y", data: getChartData('voltageThdY', 5, true).map(d => d.value) },
            { name: "B", data: getChartData('voltageThdB', 5, true).map(d => d.value) },
          ]}
          labels={getChartData('voltageThdR', 5, true).map(d => d.name)}
          title=" "
          xAxisLabel="Time"
          yAxisLabel="THD %"
        />
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={1}>
        {boxData.map((box, index) => (
          <Grid item xs={12} sm={6} md={2.4} key={index}>
            <Paper elevation={3}>
              <Box height={box.height} display="flex" flexDirection="column">
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="white"
                >
                  <Typography sx={{ color: "rgba(3, 3, 62, 0.9)", fontWeight: "bold" }}>
                    {box.heading}
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="rgba(3, 3, 62, 0.9)"
                >
                  <Typography sx={{ color: "white", fontWeight: "bold" }}>
                    {box.value}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
        {chartData.map((data, index) => (
          <Grid item xs={12} sm={3} md={data.md || 4} key={index}>
            <Paper
              elevation={3}
              style={{ padding: 5, backgroundColor: "rgba(3, 3, 62, 0.9)", position: "relative" }}
            >
              <Box
                height={300}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" marginBottom={1}>
                  <Typography sx={{ color: "white", fontWeight: "bold", marginLeft:'20px', marginTop:'20px' }}>
                    {data.title}
                  </Typography>
                  <Maximize2 
                    color="white" 
                    size={20} 
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOpenModal(data.title, data.chart)}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  {data.chart}
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <ChartModal 
        open={modalOpen} 
        handleClose={handleCloseModal} 
        title={modalContent?.title}
      >
        {modalContent?.chartComponent}
      </ChartModal>
    </div>
  );
}