import React, { useState } from "react";
// import "../style/login.css";
import "../assets/css/Login.css";
import { apiLogin } from "../api/Api.login";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");

  const navigate = useNavigate();
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await apiLogin(username, password);
      console.log("response", result.data);
      localStorage.setItem("token", result.data.data.token);
      localStorage.setItem("userID", result.data.data.userId);
      localStorage.setItem("refreshToken", result.data.data.refreshToken);
      localStorage.setItem("tokenExpiredAt", result.data.data.tokenExpiredAt);
      console.log("Submitted:", { username, password });
      if (result.data.status === "success") {
        navigate("/welcome");
      }
    } catch (error) {
      console.error("Error adding Line:", error);
      handleSnackbarOpen("Error loggin in. Please try again.", "error");
    }
  };
 
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-offset-4 col-md-4 col-sm-offset-3 col-sm-6">
            <div className="form-container">
              <div className="right-content">
                <h3 className="form-title">Login</h3>
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      value={username}
                      onChange={handleUsernameChange}
                      placeholder="Enter Username"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter your password"
                      required
                    />
                  </div>
                  <div className="remember-me">
                    <input type="checkbox" className="checkbox" />
                    <span className="check-label">Remember Me</span>
                  </div>
                  <a href="" className="forgot">
                    Forgot Password
                  </a>
                  <button type="submit" className="btn signin signinpointer">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        className="snackbar-right"
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}




// import React, { useEffect, useState } from "react";
// import "../style/login.css";
// import "../assets/css/Login.css";
// import { apiLogin } from "../api/Api.login";
// import { useNavigate } from "react-router-dom";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';

// import axios from "axios";
// import { Button } from "@mui/material";
// export default function Login() {
//   const [username, setUsername] = useState("ml_superadmin");
//   const [password, setPassword] = useState("123456");
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [error, setError] = useState(null);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [severity, setSeverity] = useState("success");
//   const [responseData, setResponseData] = useState(null);

//   const navigate = useNavigate();
//   const handleUsernameChange = (event) => {
//     setUsername(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };
//   const handleSnackbarOpen = (message, severity) => {
//     setSnackbarMessage(message);
//     setSeverity(severity);
//     setOpenSnackbar(true);
//   };

//   useEffect(()=>{
//     setInterval(() => {
//       handleSubmit() 
//     }, 10000);
//   },[])
//   const handleSubmit = (event) => {
//     const xhr = new XMLHttpRequest();
//     const url = "http://13.233.87.102:3000/api/auth/signin";
    
//     xhr.open("POST", url, true);
//     xhr.setRequestHeader("Content-Type", "application/json");
    
//     xhr.onreadystatechange = function () {
//       if (xhr.readyState === 4) {
//         if (xhr.status === 200) {
//           // Success case
//           const result = {
//             data: JSON.parse(xhr.responseText)
//           };
//           console.log("response", result.data);
//           console.log("Submitted:", { username, password });
//           console.log("response data:", responseData);
//           setResponseData(result);
//           setModalOpen(true);
//         } else {
//           // Error case
//           const error = {
//             message: xhr.statusText || "Request failed"
//           };
//           setResponseData(error);
//           setModalOpen(true);
//           console.error("Error adding Line:", error);
//           handleSnackbarOpen(error.message, "error");
//         }
//       }
//     };
  
//     // Handle network errors
//     xhr.onerror = function() {
//       const error = {
//         message: "Network error occurred"
//       };
//       setResponseData(error);
//       setModalOpen(true);
//       console.error("Error adding Line:", error);
//       handleSnackbarOpen(error.message, "error");
//     };
  
//     // Send the request
//     const data = JSON.stringify({
//       userId: username,
//       password: password
//     });
  
//     try {
//       xhr.send(data);
//     } catch (error) {
//       console.error("Error sending request:", error);
//       handleSnackbarOpen(error.message, "error");
//     }
//   };
//   const handleLogin = async (event) => {
//     // event.preventDefault();
//     try {
//       const response = await fetch("https://mlapi.digineous.com/api/auth/signin", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           userId: username,
//           password: password,
//           // loginfrom: 'W',
//           // fcmtoken: 'asfasfasf'
//         }),
//       });
  
//       const data = await response.json();
//       console.log("response", data);
  
//       if (response.ok && data.status === "success") {
//         setResponseData(data);
//         setModalOpen(true);
//       } else {
//         setResponseData(data);
//         setModalOpen(true);
//         handleSnackbarOpen("Invalid credentials. Please try again.", "error");
//       }
//     } catch (error) {
     
//       console.error("Error logging in:", error);
//       handleSnackbarOpen("Error logging in. Please try again.", "error");
//     }
//   };
  
//   return (
//     <div>
//       <div className="container">
//         <div className="row">
//           <div className="col-md-offset-4 col-md-4 col-sm-offset-3 col-sm-6">
//             <div className="form-container">
//               <div className="right-content">
//                 <h3 className="form-title">Login</h3>
//                 <form className="form-horizontal" onSubmit={handleSubmit}>
//                   <div className="form-group">
//                     <label>Username</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       value={username}
//                       onChange={handleUsernameChange}
//                       placeholder="Enter Username"
//                       required
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label>Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       value={password}
//                       onChange={handlePasswordChange}
//                       placeholder="Enter your password"
//                       required
//                     />
//                   </div>
//                   <div className="remember-me">
//                     <input type="checkbox" className="checkbox" />
//                     <span className="check-label">Remember Me</span>
//                   </div>
//                   <a href="" className="forgot">
//                     Forgot Password
//                   </a>
//                   <button type="submit" className="btn signin signinpointer">
//                      Login with credential 1
//                   </button>
//                 </form>
//                 <button style={{padding:'14px 11px',background:'#036574',color:'white',width:'100%'}} onClick={handleLogin}>
//                   Login with credential 2
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Dialog open={modalOpen} onOpenChange={setModalOpen}>
//         <DialogTitle className="text-xl font-bold">Api called!</DialogTitle>
//         <DialogContent className="space-y-4">
//           <div className="bg-gray-50 p-4 rounded">
//             <h4 className="font-medium mb-2">Response Data:</h4>
//             <pre className="whitespace-pre-wrap text-sm">
//               {responseData ? JSON.stringify(responseData, null, 2) : ''}
//             </pre>
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => setModalOpen(false)}
//             className="bg-blue-500 hover:bg-blue-600 text-white"
//           >
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         className="snackbar-right"
//         onClose={() => setOpenSnackbar(false)}
//       >
//         <MuiAlert
//           onClose={() => setOpenSnackbar(false)}
//           severity={severity}
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </MuiAlert>
//       </Snackbar>
//     </div>
//   );
// }
