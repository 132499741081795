import { apiCall } from "../api";

const getDeviceNumber = () => {
  const userID = localStorage.getItem('userID');
  switch (userID) {
    case 'aura_laser':
      return 43;
    case 'gayatree_polymers':
      return 2;
    default:
      return 0;
  }
};

export const EMSDashboardData = {
  getEMSDasboardData: () => apiCall('get', `/common/energyMeasurements/${getDeviceNumber()}`),
  getEMSRawData: (startDate, endDate) => {
    const deviceNo = getDeviceNumber();
    return apiCall('post', '/common/energyReport', {
      deviceNo,
      startDate,
      endDate
    });
  },
  getDailyConsumptionPerDay: () => apiCall('get', `/common/getConsumptionPerDay/${getDeviceNumber()}`)
};