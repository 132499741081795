export const formatFurnace2Data = (data) => {
    return data.map(item => ({
        "Date Time": item["Date Time"],
        "Zone 1 Temp (°C)": item["Zone 1 Temp (°C)"],
        "Zone 2 Temp (°C)": item["Zone 2 Temp (°C)"],
        "Zone 3 Temp (°C)": item["Zone 3 Temp (°C)"],
        "Zone 4 Temp (°C)": item["Zone 4 Temp (°C)"],
        "Hydrogen Flow (Nm³/h)": item["Hydrogen Flow (Nm³/h)"],
        "Nitrogen Flow (Nm³/h)": item["Nitrogen Flow (Nm³/h)"],
        "Hydrogen Purity (%)": item["Hydrogen Purity (%)"],
        "Cooling Water Temp (°C)": item["Cooling Water Temp (°C)"],
        "Nitrogen Dew Point (°C)": item["Nitrogen Dew Point (°C)"],
        "Hydrogen Dew Point (°C)": item["Hydrogen Dew Point (°C)"],
        "Conveyor Frequency (Hz)": item["Conveyor Frequency (Hz)"],
        "Tray Count (NOS)": item["Tray Count (NOS)"],
        "Chiller Pump Pressure": item["Chiller Pump Pressure"],
        "Ignitor Inlet 1": item["Ignitor Inlet 1"],
        "Ignitor Inlet 2": item["Ignitor Inlet 2"],
        "Ignitor Outlet 1": item["Ignitor Outlet 1"],
        "Ignitor Outlet 2": item["Ignitor Outlet 2"]
      }));
    };
  
  export const formatFurnace3Data = (data) => {
    return data.map(item => ({
        "Date Time": item["Date Time"],
        "Zone 1 Temp (°C)": item["Zone 1 Temp (°C)"],
        "Zone 2 Temp (°C)": item["Zone 2 Temp (°C)"],
        "Zone 3 Temp (°C)": item["Zone 3 Temp (°C)"],
        "Zone 4 Temp (°C)": item["Zone 4 Temp (°C)"],
        "Cooling Water Temp (°C)":item["Cooling Water Temp (°C)"],
        "Hydrogen Dew Point (°C)": item["Hydrogen Dew Point (°C)"],
        "Nitrogen Dew Point (°C)": item["Nitrogen Dew Point (°C)"],
        "Conveyor Frequency (Hz)":item["Conveyor Frequency (Hz)"],
        "Hydrogen Flow (Nm³/h)": item["Hydrogen Flow (Nm³/h)"],
        "Nitrogen Flow (Nm³/h)": item["Nitrogen Flow (Nm³/h)"],
        "Hydrogen Purity (%)": item["Hydrogen Purity (%)"],
        "Hydrogen Valve Open (%)": item["Hydrogen Valve Open (%)"],
        "Nitrogen Valve Open (%)":item["Nitrogen Valve Open (%)"],
        "Tray Count (NOS)": item["Tray Count (NOS)"],
        "Ignitor Inlet 1": item["Ignitor Inlet 1"],
        "Ignitor Inlet 2":item["Ignitor Inlet 2"],
        "Ignitor Outlet 1":item["Ignitor Outlet 1"],
        "Ignitor Outlet 2": item["Ignitor Outlet 2"],
        "Control Panel Ac Status": item["Control Panel Ac Status"],
        "Chiller Pump Pressur": item["Chiller Pump Pressur"]
     
    }));
  };
  
  export const formatCracker2Data = (data) => {
    return data.map(item => ({
        "Date Time": item[ "Date Time"],
        "Dryer Tower 1 Temp (°C)":item["Dryer Tower 1 Temp (°C)"],
        "Dryer Tower 2 Temp (°C)": item["Dryer Tower 2 Temp (°C)"],
        "Furnace Low Temp (°C)": item["Furnace Low Temp (°C)"],
        "Furnace High Temp (°C)": item[ "Furnace High Temp (°C)"],
        "Furnace Control Temp (°C)": item[ "Furnace Control Temp (°C)"],
        "Ammonia Supply Motorised Valve Open (%)": item["Ammonia Supply Motorised Valve Open (%)"],
        "Ammonia Flow (Nm³/h)": item["Ammonia Flow (Nm³/h)"],
        "Nitrogen Flow In (Nm³/h))": item[ "Nitrogen Flow In (Nm³/h))"],
        "Cracked Ammonia Flow (Nm³/h)": item["Cracked Ammonia Flow (Nm³/h)"],
        "Hydrogen Flow Trans 1 (Nm³/h)": item["Hydrogen Flow Trans 1 (Nm³/h)"],
        "Hydrogen Flow Trans 2 (Nm³/h)": item["Hydrogen Flow Trans 2 (Nm³/h)"],
        "Dryer Tower 1 Pressure (kg/cm ²)":item[ "Dryer Tower 1 Pressure (kg/cm ²)"],
        "Dryer Tower 2 Pressure (kg/cm ²)": item["Dryer Tower 2 Pressure (kg/cm ²)"],
        "PSA Tower 1 Pressure (kg/cm ²)": item["PSA Tower 1 Pressure (kg/cm ²)"],
        "PSA Tower 2 Pressure (kg/cm ²)": item["PSA Tower 2 Pressure (kg/cm ²)"],
        "Current Dryer Time (min)":item["Current Dryer Time (min)"],
        "Current PSA Cycle Time (secs)":item["Current PSA Cycle Time (secs)"],
        "Dew Point Meter": item["Dew Point Meter"],
        "Hps Ammonia After Prv": item["Hps Ammonia After Prv"],
        "Hps Buffer Tank": item["Hps Buffer Tank"],
        "Inlet Air Pressure": item["Inlet Air Pressure"],
        "Lps Ammonia Inlet": item["Lps Ammonia Inlet"],
        "Lps Surge Vessel": item["Lps Surge Vessel"],
        "Lps Water": item["Lps Water"]
    }));
  };
  
  export const formatCracker3Data = (data) => {
    return data.map(item => ({
        "Date Time": item["Date Time"],
        "Dryer Tower 1 Temp (°C)": item["Dryer Tower 1 Temp (°C)"],
        "Dryer Tower 2 Temp (°C)": item["Dryer Tower 2 Temp (°C)"],
        "Furnace Low Temp (°C)": item["Furnace Low Temp (°C)"],
        "Furnace High Temp (°C)": item["Furnace High Temp (°C)"],
        "Furnace Control Temp (°C)": item["Furnace Control Temp (°C)"],
        "Hot Water Bath Temp (°C)": item["Hot Water Bath Temp (°C)"],
        "Hydrogen Flow (Nm³/h)": item["Hydrogen Flow (Nm³/h)"],
        "Crad Ammonia (Nm³/h)":item["Crad Ammonia (Nm³/h)"],
        "Exhaust Flow (Nm³/h)": item["Exhaust Flow (Nm³/h)"],
        "Ammonia Inlet Flow (Nm³/h)": item["Ammonia Inlet Flow (Nm³/h)"],
        "Dryer Tower 1 Pressure (kg/cm ²)": item["Dryer Tower 1 Pressure (kg/cm ²)"],
        "Dryer Tower 2 Pressure (kg/cm ²)":item["Dryer Tower 2 Pressure (kg/cm ²)"],
        "PSA Tower 1 Pressure (kg/cm ²)": item["PSA Tower 1 Pressure (kg/cm ²)"],
        "PSA Tower 2 Pressure (kg/cm ²)": item["PSA Tower 2 Pressure (kg/cm ²)"],
        "Current PSA Cycle Time (min)": item["Current PSA Cycle Time (min)"],
        "Current Dryer Time (mins)": item["Current Dryer Time (mins)"],
        "Hydrogen Product Valve Delay (mins)": item["Hydrogen Product Valve Delay (mins)"],
        "Ammonia Supply Motorized Valve Open (%)": item["Ammonia Supply Motorized Valve Open (%)"],
        "Dew Point Meter": item[ "Dew Point Meter"],
        "Hps Ammonia After Prv": item["Hps Ammonia After Prv"],
        "Hot Water Pump Pressure": item[ "Hot Water Pump Pressure"],
        "Hps Buffer Tank":item["Hps Buffer Tank"],
        "Lps Ammonia Inlet": item["Lps Ammonia Inlet"],
        "Lps Inst Air":item["Lps Inst Air"],
        "Lps Surge Vessel": item["Lps Surge Vessel"],
        "Lps Water": item["Lps Water"]
    }));
  };