import React from "react";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { logoBase64 } from '../utils/logoBase64';

const DownloadButton = ({ apiCall, fileName, selectedMachine, selectedDate, selectedShift }) => {
  const handleDownload = async () => {
    try {
      const { data, formatFunction } = await apiCall();
      const formattedData = formatFunction(data);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Report");

      // Add logo
      const logoId = workbook.addImage({
        base64: logoBase64,
        extension: 'png',
      });
      worksheet.addImage(logoId, {
        tl: { col: 0, row: 0 },
        br: { col: 2, row: 4 }
      });

      // Set up title and headers
      worksheet.mergeCells('A1:B4');
      worksheet.mergeCells('C1:P4');
      const titleCell = worksheet.getCell('C2');
      titleCell.value = "Production Cum Process Report";
      titleCell.font = { bold: true, size: 25 };
      titleCell.alignment = { horizontal: 'center', vertical: 'middle' };

      worksheet.getCell('Q2').value = "Machine Name:";
      worksheet.getCell('R2').value = selectedMachine;
      worksheet.getCell('Q3').value = "Date:";
      worksheet.getCell('R3').value = selectedDate;
      worksheet.getCell('Q4').value = "Shift:";
      worksheet.getCell('R4').value = selectedShift;

      // Add data
      const headers = Object.keys(formattedData[0]);
      worksheet.addRow(headers);

      worksheet.getRow(5).eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF1FAEC5' }
        };
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });

      formattedData.forEach((row) => {
        worksheet.addRow(Object.values(row));
      });

      // Set cell alignments
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 5) {
          row.eachCell((cell) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
          });
        }
      });

      // Adjust column widths
      worksheet.columns.forEach(column => {
        column.width = Math.max(15, ...worksheet.getColumn(column.letter).values.map(v => v ? v.toString().length : 0));
      });

      // Unlock all cells
      worksheet.properties.defaultRowHeight = 15;
      worksheet.properties.defaultColWidth = 15;
      worksheet.properties.outlineLevelRow = 0;
      worksheet.properties.outlineLevelCol = 0;
      worksheet.properties.tabColor = { argb: 'FF00FF00' };

      // Generate and save the file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };

  return (
    <Button variant="contained" onClick={handleDownload}>
      Download Report
    </Button>
  );
};

export default DownloadButton;