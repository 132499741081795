

import client from './client';

export const alertApi = {
  getAlerts: async () => {
    return await client.get('/alert/timeBaseAlertDetail');
  },
  addAlert: async (alertData) => {
    return await client.post('/alert/addTimeBaseAlert', alertData);
  },
  updateAlert: async (id, alertData) => {
    return await client.put(`/alert/updateTimeBaseAlert/${id}`, alertData);
  },
  deleteAlert: async (id) => {
    return await client.delete(`/alert/removeTimeBaseAlert/${id}`);
  }
};