import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { apiMachineMaster } from "../api/api.addmachine";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { apigetMachine } from "../api/apigetmachine";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { apiUpdateMachineMaster } from "../api/api.updatemachine";
import { apigetLines } from "../api/api.getline";
import { apiGetPlant } from "../api/api.getplant";
import { apiDeleteMachine } from "../api/api.deletemachine";
import DeleteConfirmationModal from "./deletemodal";
import { Skeleton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuthCheck } from "../utils/Auth";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1FAEC5",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MachineMaster() {
  const [machinedata, setMachinedata] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [addOpen, setAddOpen] = useState(false);

  const [plantNO, setPlantNO] = useState("");

  const [updateOpen, setUpdateOpen] = useState(false);

  const [severity, setSeverity] = useState("success");
  const [error, setError] = useState(null);
  const [lineData, setLineData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [plantData, setPlantData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMachineId, setDeleteMchineId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [filteredLineData, setFilteredLineData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [updatedMachineData, setUpdatedMachineData] = useState({
    machineId: "",
    plantNo: "",
    lineNo: "",
    machineName: "",
    displayMachineName: "",
    lineProductionCount: "",
    cycleTime: "",
  });

  useAuthCheck();

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const [plantResult, machineResult, lineResult] = await Promise.all([
          apiGetPlant(),
          apigetMachine(),
          apigetLines(),
        ]);
        const plantData = plantResult.data.data;
        const machineData = machineResult.data.data;
        const lineData = lineResult.data.data;
        console.log("line data:", lineData);
        setPlantData(plantData);
        setMachinedata(machineData);
        setLineData(lineData);

        const sprlPlant = plantData.find((plant) => plant.plantName === "ADM");
        if (sprlPlant) {
          setPlantNO(sprlPlant.plantNo);
          const filteredLines = lineData.filter(
            (line) => line.plantNo === sprlPlant.plantNo
          );
          setFilteredLineData(filteredLines);
        }
      } catch (error) {
        setError(error.message);
        handleSnackbarOpen(error.message, "error");
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, [refreshData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const sprlPlant = plantData.find((plant) => plant.plantName === "SPRL");
    const newMachineData = {
      ...updatedMachineData,
      plantNo: sprlPlant ? sprlPlant.plantNo : "",
    };
    try {
      const result = await apiMachineMaster(newMachineData);
      setAddOpen(false);
      handleSnackbarOpen("Machine added successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      handleSnackbarOpen("Error adding machine. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };
  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    const sprlPlant = plantData.find((plant) => plant.plantName === "SPRL");
    const newMachineData = {
      ...updatedMachineData,
      plantNo: sprlPlant ? sprlPlant.plantNo : "",
    };
    try {
      const result = await apiUpdateMachineMaster(newMachineData);
      setUpdateOpen(false);
      // await getmachine();
      handleSnackbarOpen("Machine updated successfully!", "success"); // Pass severity as "success"
      setRefreshData((prev) => !prev);
      console.log("response", result.data);
      setUpdatedMachineData({
        machineId: "",
        plantNo: "",
        lineNo: "",
        machineName: "",
        displayMachineName: "",
        lineProductionCount: "",
        cycleTime: "",
      });
    } catch (error) {
      console.error("Error updating machine:", error);
      handleSnackbarOpen("Error updating machine. Please try again.", "error"); // Pass severity as "error"
    }
  };

  const handleInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    const { name, value } = e.target;
    setUpdatedMachineData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditClick = (row) => {
    console.log(row);
    setUpdatedMachineData(row);
    setUpdateOpen(true);
  };

  const handleDeleteClick = (row) => {
    setDeleteMchineId(row.machineNo);
    setDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      await apiDeleteMachine(deleteMachineId);
      handleSnackbarOpen("Machine Deleted successfully!", "success");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.error("Error deleting Machine:", error);
      handleSnackbarOpen("Error deleting Machine. Please try again.", "error");
    } finally {
      setDeleteModalOpen(false);
    }
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, machinedata.length - page * rowsPerPage);
  const handleModalClose = () => {
    // Reset the form data
    setUpdatedMachineData({
      machineId: "",
      plantNo: "",
      lineNo: "",
      machineName: "",
      displayMachineName: "",
      lineProductionCount: "",
      cycleTime: "",
    });

    setAddOpen(false);
    setUpdateOpen(false);
  };
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ padding: "0px 20px" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "20px 0px 20px 0px",
            }}
          >
            <h2>Machine Master</h2>
            <div style={{}}>
              <Button
                onClick={() => setAddOpen(true)}
                style={{
                  fontWeight: "500",
                  borderRadius: "4px",
                  color: "gray",
                  border: "2px solid gray",
                  padding: "5px",
                  marginBottom: "5px",
                }}
              >
                Add New &nbsp;
                <FontAwesomeIcon
                  style={{ fontSize: "18px", color: "gray" }}
                  icon={faPlus}
                />
              </Button>
            </div>
          </div>
          <Box sx={{ marginTop: "20px", maxHeight: "500px", overflow: "auto" }}>
            <Table
              size="small"
              style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
            >
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell className="table-cell">
                    Machine No
                  </StyledTableCell> */}
                  <StyledTableCell className="table-cell">
                    Plant Name
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    Line Name
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    Machine Id
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    Machine Code
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    Machine Name
                  </StyledTableCell>
                  <StyledTableCell className="table-cell">
                    Created Date
                  </StyledTableCell>
                 
                  <StyledTableCell className="table-cell">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {machinedata.length === 0
                  ? Array.from(Array(5).keys()).map((index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell> */}
                        <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Skeleton animation="wave" />
                        </StyledTableCell>
                     
                      </StyledTableRow>
                    ))
                  : machinedata
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          {/* <StyledTableCell className="table-cell">
                            {row.machineNo}
                          </StyledTableCell> */}
                          <StyledTableCell className="table-cell">
                            {row.plantName}
                          </StyledTableCell>
                          <StyledTableCell className="table-cell">
                            {row.lineName}
                          </StyledTableCell>
                          <StyledTableCell className="table-cell">
                            {row.machineId}
                          </StyledTableCell>
                          <StyledTableCell className="table-cell">
                            {row.machineName}
                          </StyledTableCell>
                          <StyledTableCell className="table-cell">
                            {row.displayMachineName}
                          </StyledTableCell>
                          <StyledTableCell className="table-cell">
                            {row.createdAt}
                          </StyledTableCell>
                          
                          <StyledTableCell
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                            }}
                            className="table-cell"
                          >
                            <IconButton onClick={() => handleEditClick(row)}>
                              <EditIcon />
                            </IconButton>
                            <div
                              className="divider"
                              style={{
                                height: "20px",
                                width: "2px",
                                backgroundColor: "#0003",
                              }}
                            ></div>
                            <IconButton
                              style={{ color: "#FF3131" }}
                              onClick={() => handleDeleteClick(row)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                {emptyRows > 0 && (
                  <StyledTableRow style={{ height: 53 }}>
                    <StyledTableCell
                      colSpan={8}
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        {`No further data available`}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={machinedata.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          <Modal open={addOpen} onClose={handleModalClose}>
            <div
              style={{
                borderRadius: "10px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                padding: "20px",
                minWidth: "500px",
              }}
            >
              <button
                onClick={handleModalClose}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: "30px",
                }}
              >
                &times;
              </button>
              <h2>Add New Machine</h2>
              <hr />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <FormControl sx={{ width: "26ch" }}>
                  <InputLabel>Plant Name</InputLabel>
                  <Select
                    name="plantNo"
                    value={updatedMachineData?.plantNo}
                    onChange={handleInputChange}
                  >
                    {plantData.map((plant, index) => (
                      <MenuItem key={index} value={plant.plantNo}>
                        {plant.plantName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "26ch" }}>
                  <InputLabel>Line Name</InputLabel>
                  <Select
                    value={updatedMachineData.lineNo}
                    name="lineNo"
                    label="Line"
                    onChange={handleInputChange}
                  >
                    {filteredLineData.map((line) => (
                      <MenuItem key={line.lineNo} value={line.lineNo}>
                        {line.lineName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {/* <TextField
                    name="machineName"
                    label="Machine Code"
                    value={updatedMachineData?.machineName}
                    onChange={handleInputChange}
                  /> */}
                <TextField
                  name="machineId"
                  label="Machine Id"
                  value={updatedMachineData?.machineId}
                  onChange={handleInputChange}
                  // style={{ marginRight: "10px" }}
                />
                <FormControl sx={{ width: "26ch" }}>
                  <InputLabel>Machine Code</InputLabel>
                  <Select
                    name="machineName"
                    value={updatedMachineData?.machineName}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="M-1">M-1</MenuItem>
                    <MenuItem value="M-2">M-2 </MenuItem>
                    <MenuItem value="M-3">M-3</MenuItem>
                    <MenuItem value="M-4">M-4</MenuItem>
                    <MenuItem value="M-5">M-5</MenuItem>
                    <MenuItem value="M-6">M-6</MenuItem>
                    <MenuItem value="M-7">M-7</MenuItem>
                    <MenuItem value="M-8">M-8</MenuItem>
                    <MenuItem value="M-9">M-9</MenuItem>
                    <MenuItem value="M-10">M-10</MenuItem>
                    <MenuItem value="M-11">M-11</MenuItem>
                    <MenuItem value="M-12">M-12</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <FormControl sx={{ width: "26ch" }}>
                  <TextField
                    name="displayMachineName"
                    label=" Display Machine Name"
                    onChange={handleInputChange}
                    value={updatedMachineData?.displayMachineName}
                  />
                </FormControl>
                <FormControl sx={{ width: "26ch" }}>
                  <InputLabel>Line Production Count</InputLabel>
                  <Select
                    name="lineProductionCount"
                    value={updatedMachineData?.lineProductionCount}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="N">N</MenuItem>
                    <MenuItem value="Y">Y</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {/* <TextField
                    name="cycleTime"
                    label="Cycle Time"
                    value={updatedMachineData?.cycleTime}
                    onChange={handleInputChange}
                  /> */}
              </div>

              <Button
                onClick={handleAddSubmit}
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
              >
                Add
              </Button>
            </div>
          </Modal>
          <Modal open={updateOpen} onClose={handleModalClose}>
            <div
              style={{
                borderRadius: "10px",

                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                padding: "20px",
                minWidth: "500px",
              }}
            >
              <button
                onClick={handleModalClose}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: "30px",
                }}
              >
                &times;
              </button>
              <h2>Update Machine</h2>
              <hr />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <FormControl sx={{ width: "26ch" }}>
                  <InputLabel>Plant Name</InputLabel>
                  <Select
                    name="plantNo"
                    value={updatedMachineData?.plantNo}
                    onChange={handleInputChange}
                  >
                    {plantData.map((plant, index) => (
                      <MenuItem key={index} value={plant.plantNo}>
                        {plant.plantName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "26ch" }}>
                  <InputLabel>Line Name</InputLabel>
                  <Select
                    name="lineNo"
                    value={updatedMachineData?.lineNo}
                    onChange={handleInputChange}
                  >
                    {filteredLineData.map((line) => (
                      <MenuItem key={line.id} value={line.lineNo}>
                        {line.lineName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value="1">Pathredi</MenuItem>
                  <MenuItem value="2">Ghaziabad </MenuItem>
                  <MenuItem value="3">Head Office</MenuItem> */}
                    {/* <MenuItem value={4}>Machine 4</MenuItem>
        <MenuItem value={5}>Machine 5</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {/* <TextField
                    name="machineName"
                    label="Machine Code"
                    value={updatedMachineData?.machineName}
                    onChange={handleInputChange}
                    // style={{ marginRight: "10px" }}
                  /> */}
                <TextField
                  name="machineId"
                  label="Machine Id"
                  value={updatedMachineData?.machineId}
                  onChange={handleInputChange}
                  // style={{ marginRight: "20px" }}
                />
                <FormControl sx={{ width: "26ch" }}>
                  <InputLabel>Machine Code</InputLabel>
                  <Select
                    name="machineName"
                    value={updatedMachineData?.machineName}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="M-1">M-1</MenuItem>
                    <MenuItem value="M-2">M-2 </MenuItem>
                    <MenuItem value="M-3">M-3</MenuItem>
                    <MenuItem value="M-4">M-4</MenuItem>
                    <MenuItem value="M-5">M-5</MenuItem>
                    <MenuItem value="M-6">M-6</MenuItem>
                    <MenuItem value="M-7">M-7</MenuItem>
                    <MenuItem value="M-8">M-8</MenuItem>
                    <MenuItem value="M-9">M-9</MenuItem>
                    <MenuItem value="M-10">M-10</MenuItem>
                    <MenuItem value="M-11">M-11</MenuItem>
                    <MenuItem value="M-12">M-12</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <FormControl sx={{ width: "26ch" }}>
                  <TextField
                    name="displayMachineName"
                    label=" Display Machine Name"
                    onChange={handleInputChange}
                    value={updatedMachineData?.displayMachineName}
                  />
                </FormControl>
                <FormControl sx={{ width: "26ch" }}>
                  <InputLabel>Line Production Count</InputLabel>
                  <Select
                    name="lineProductionCount"
                    value={updatedMachineData?.lineProductionCount}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="N">N</MenuItem>
                    <MenuItem value="Y">Y</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {/* <TextField
                    name="cycleTime"
                    label="Cycle Time"
                    value={updatedMachineData?.cycleTime}
                    onChange={handleInputChange}
                  /> */}
              </div>

              <Button
                onClick={handleUpdateSubmit}
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
              >
                Update
              </Button>
            </div>
          </Modal>
          <DeleteConfirmationModal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={handleConfirmDelete}
          />

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
          >
            <MuiAlert
              onClose={() => setOpenSnackbar(false)}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </div>
      )}
    </div>
  );
}

// const handleDeleteClick = async (id) => {
//   console.log("val:", id);
//   try {
//     const result = await apiDeleteMachine(id.machineNo);
//     console.log("response", result.data);
//     handleSnackbarOpen("Machine Deleted successfully!", "success");
//     setRefreshData((prev) => !prev);
//   } catch (error) {
//     console.error("Error deleting Machine:", error);
//     handleSnackbarOpen("Error deleting Machine. Please try again.", "error");
//   }
// };

// useEffect(() => {
//   if (updatedMachineData.plantNo) {
//     const filteredLines = lineData.filter(
//       (line) => line.plantNo === updatedMachineData.plantNo
//     );
//     setFilteredLineData(filteredLines);
//   }
// }, [updatedMachineData.plantNo, lineData]);

// const getmachine = async () => {
//   try {
//     const result = await apigetMachine();
//     console.log(result?.data.data);
//     setMachinedata(result?.data.data);
//   } catch (error) {
//     setError(error.message);
//     handleSnackbarOpen("Error fetching machines: " + error.message, "error");
//   }
// };

// useEffect(() => {
//   const getPlant = async () => {
//     try {
//       const result = await apiGetPlant();
//       console.log("Result data plant:", result.data.data); // Log the data property
//       setPlantData(result.data.data); // Set plantData to result.data
//     } catch (error) {
//       setError(error.message);
//       handleSnackbarOpen(error.message, "error");
//     }
//   };
//   getPlant();
// }, [refreshData]);
// useEffect(() => {
//   const getmachine = async () => {
//     try {
//       const result = await apigetMachine();
//       console.log("Result data machine:", result.data.data);
//       setMachinedata(result.data.data);
//     } catch (error) {
//       setError(error.message);
//       handleSnackbarOpen(error.message, "error");
//     }
//   };
//   return () => {
//     getmachine();
//   };
// }, [refreshData]);

// useEffect(() => {
//   const getPlant = async () => {
//     try {
//       const result = await apigetLines();
//       console.log("Result data line:", result.data.data); // Log the data property
//       setLineData(result.data.data); // Set plantData to result.data
//     } catch (error) {
//       setError(error.message);
//       handleSnackbarOpen(error.message, "error");
//     }
//   };
//   getPlant();
// }, [refreshData]);
